import React from "react";
import "./Track.scss";
import Typography from "./@setproduct-ui/core/Typography";
import Card from "./@setproduct-ui/core/Card";
import Button from "./@setproduct-ui/core/Button";
import { Grid } from '@material-ui/core';
import DefaultInput from "./@setproduct-ui/core/Inputs/DefaultInput";
import {useParams, useHistory, useRouteMatch} from "react-router-dom";
import SuspenseLoader from "./components/SuspenseLoader";


const friendlyStatus = {
    'created': 'Placed',
    'billed': 'Billed',
    'picked': 'Dispatched',
    'delivered': 'Delivered',
    'cancelled': 'Cancelled'
}

const AUTO_REFRESH_TIME = 5000  // in miliseconds

const formatDate = (date) => {
    const deliveryTime = new Date(date.replace(/-/g, "/"));
    return `${deliveryTime.toDateString()}, ${deliveryTime.toLocaleTimeString("en-IN")}`;
}

function useInterval(callback, delay) {
    const intervalRef = React.useRef();
    const callbackRef = React.useRef(callback);
  
    // Remember the latest callback:
    //
    // Without this, if you change the callback, when setInterval ticks again, it
    // will still call your old callback.
    //
    // If you add `callback` to useEffect's deps, it will work fine but the
    // interval will be reset.
  
    React.useEffect(() => {
      callbackRef.current = callback;
    }, [callback]);
  
    // Set up the interval:
  
    React.useEffect(() => {
      if (typeof delay === 'number') {
        intervalRef.current = window.setInterval(() => callbackRef.current(), delay);
  
        // Clear interval if the components is unmounted or the delay changes:
        return () => window.clearInterval(intervalRef.current);
      }
    }, [delay]);
  
    // Returns a ref to the interval ID in case you want to clear it manually:
    return intervalRef;
  }

const getHeaderClass = (status) => {
    if (status === 'delivered') {
        return 'header-card-delivered';
    }
    if (status === 'cancelled') {
        return 'header-card-cancelled';
    }
    return ''
}


function Timeline({deliveryStatus, orderCreatedAt, billedAt, pickedUpAt, deliveredAt, otp}) {
    const deliveryStatuses = ['created', 'billed', 'picked', 'delivered'];

    console.log('deliveryStatus: ', deliveryStatus);

    let formattedDeliveredAt, formattedBilledAt, formattedOrderCreatedAt, formattedPickedUpAt;

    const isStatusComplete = (status) => {
        return deliveryStatuses.indexOf(status) <= deliveryStatuses.indexOf(deliveryStatus);
    }

    if (deliveredAt) {
        formattedDeliveredAt = formatDate(deliveredAt);
    }

    if (orderCreatedAt) {
        formattedOrderCreatedAt = formatDate(orderCreatedAt);
    }

    if (billedAt) {
        formattedBilledAt = formatDate(billedAt);
    }

    if (pickedUpAt) {
        formattedPickedUpAt = formatDate(pickedUpAt);
    }

    return (
        <Card className={"timeline-container"}>
            <div id="tracker">
                <div className={`tracking-step ${isStatusComplete('created') ? 'green-border' : 'gray-border'}`}>
                    <div className={"tracking-step-headline"}>
                        Order Placed
                    </div>
                    <div className={"tracking-step-subheading"}>
                        {formattedOrderCreatedAt}
                    </div>
                </div>
                <div
                    className={`tracking-step ${isStatusComplete('billed') ? 'green-border' : 'gray-border'}`}>
                    <div className={"tracking-step-headline"}>
                        Order Billed
                    </div>
                    <div className={"tracking-step-subheading"}>
                        {formattedBilledAt}
                    </div>
                </div>
                <div
                    className={`tracking-step ${isStatusComplete('picked') ? 'green-border' : 'gray-border'}`}>
                    <div className={"tracking-step-headline"}>
                        Order Dispatched
                    </div>
                    <div className={"tracking-step-subheading"}>
                        {formattedPickedUpAt}
                        {isStatusComplete('picked') && otp &&
                            <div>
                                <hr />
                                OTP: <b>{otp}</b>
                            </div>
                        }
                    </div>
                </div>
                <div
                    className={`tracking-step ${isStatusComplete('delivered') ? 'green-border' : 'gray-border'}`}>
                    <div className={"tracking-step-headline"}>
                        Order Delivered
                    </div>
                    <div className={"tracking-step-subheading"}>
                        {formattedDeliveredAt}
                    </div>
                </div>
            </div>
        </Card>
    )
}

function Track() {
    const [orderInfo, setOrderInfo] = React.useState();
    let match = useRouteMatch("/track/:trackingId");

    const {trackingId} = useParams();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [isPaused, setIsPaused] = React.useState(false);

    React.useEffect(() => {
    }, []);

    const fetchOrderInfo = (trackingId, showLoader=false) => {
        if(showLoader){
            setLoading(true)
        }
        fetch(`https://retail-api.loadshare.net/partner-api/general/track/order/${trackingId}`).then(res => res.json()).then(res => {
            console.log("res: ", res);
            if (res.status === 0) {
                if(res.order.deliveryStatus === "delivered" || res.order.deliveryStatus === "cancelled"){
                    setIsPaused(true)
                }
                setOrderInfo(res.order);
            } else {
                setIsPaused(true)
            }
            if(showLoader){
                setLoading(false)
            }
        })
        
    }

    const interval = useInterval(() => {
        fetchOrderInfo(trackingId, false);
      }, isPaused ? null : AUTO_REFRESH_TIME)

    React.useEffect(() => {
        console.log("match changed: ", match);
    }, [match]);

    React.useEffect(() => {
        console.log('tracking useeffect', trackingId, match);
        if (trackingId) {
            fetchOrderInfo(trackingId, false);
            
        } else {
            setOrderInfo(null);
        }
    //eslint-disable-next-line
    }, [trackingId,]);

    React.useEffect(() => {
        return function cleanup() {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            clearInterval(interval.current)
        }
    //eslint-disable-next-line
    }, [])

    const handleRefresh = () => {
        if (trackingId) {
            fetchOrderInfo(trackingId, true);
        } else {
            setOrderInfo(null);
        }
    }

    const getDeliveryTimeText = () => {
        if (orderInfo && orderInfo.deliveryStatus !== "cancelled") {
            if (orderInfo.deliveryStatus === "delivered") {
                return `Delivered at ${formatDate(orderInfo.deliveredAt)}`;
            } else {
                return orderInfo.etaText;
            }
        }
        return "";
    }

    // console.log('orderInfo: ', orderInfo);

    const [trackingIdInput, setTrackingIdInput] = React.useState('');

    const onTrackBtnClick = () => {
        history.push(`/track/${trackingIdInput}`);
    }

    const getMaskedNumber = (phoneNumber) => {
        let newNumber = phoneNumber.slice(0, 2) + "XXXXX" + phoneNumber.slice(-3)
        return newNumber
    }

    if (!trackingId) {
        return <Card id={"enter-tracking-code"}>
            <Typography type={"h6"}>Enter Tracking ID</Typography>
            <DefaultInput
                value={trackingIdInput}
                placeholder={"XXXXXXXXXX"}
                label={"Tracking ID"}
                view={"outlined"}
                onChange={(e) => setTrackingIdInput(e.target.value)}
            />
            <Button id={"track-order-btn"} onClick={onTrackBtnClick}>Track Order</Button>
        </Card>
    }

    if (orderInfo && !orderInfo.trackingId) {
        return <Typography type={"h4"} color={"danger"} colorStep={70}>Order Not Found</Typography>
    }

    return (
        <div>
            {loading && <SuspenseLoader />}
            {orderInfo &&
            <Card className={`header-card ${getHeaderClass(orderInfo.deliveryStatus)}`}>
                <div className={"tracker-heading"}>
                    <div>
                        <Grid container>
                            <Grid item xs={8} sm={10}>
                                <Typography type={"h6"} className={'tracking-id-heading'}>Tracking ID</Typography>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <Button id={"refresh-order-btn"} icon={"refresh"} type="default" dense  onClick={handleRefresh}>Refresh</Button>
                            </Grid>
                        </Grid>
                    </div>
                    
                    <Typography type={"body"} className={"tracking-id-number"}
                                colorStep={60}>{orderInfo.trackingId} ({orderInfo.phone})</Typography>
                    <Typography type={"body"}>
                        {getDeliveryTimeText()}
                    </Typography>
                    <Typography type={"body"} className={"tracking-status-badge"}>
                        {friendlyStatus[orderInfo.deliveryStatus]}
                    </Typography>
                </div>
            </Card>}

            {orderInfo && orderInfo.businessInfo &&
            <Card className={`address-card`}>
                <div className={'store-address-container'}>
                    <Typography type={"h6"}>Merchant</Typography>
                    <Typography type={"body"}
                                className={"business-name"}>{orderInfo.businessInfo.businessName}</Typography>
                    <Typography type={"body"}
                                className={"business-detail"}>{orderInfo.businessInfo.businessAddress.locality}</Typography>
                    <Typography type={"body"}
                                className={"business-detail"}>{orderInfo.businessInfo.businessAddress.city}</Typography>
                </div>

                <div className={'delivery-address-container'}>
                    <Typography type={"h6"}>Customer</Typography>
                    <Typography type={"body"} className={"business-detail"}>{orderInfo.address}</Typography>
                </div>
            </Card>}

            {orderInfo && orderInfo.deliveryStatus !== 'cancelled' &&
            <Timeline deliveryStatus={orderInfo.deliveryStatus}
                      orderCreatedAt={orderInfo.orderCreatedAt}
                      pickedUpAt={orderInfo.pickedUpAt}
                      billedAt={orderInfo.billedAt}
                      deliveredAt={orderInfo.deliveredAt}
                      otp={orderInfo.dropOtp}
            />}


            {orderInfo && orderInfo.deliveryStatus !== 'cancelled' && orderInfo.riderInfo && orderInfo.riderInfo.name &&
            <Card className={"rider-info"}>
                <Typography type={"h6"}>Delivery Info</Typography>
                <Typography type={"body"}>{orderInfo.riderInfo.name}</Typography>
                <Button icon={"phone"} onClick={() => {
                    document.location.href = "tel:91" + (orderInfo.riderInfo.phone)
                }
                }>{orderInfo.riderInfo && orderInfo.riderInfo.phone ? getMaskedNumber(orderInfo.riderInfo.phone) : "call"}</Button>
            </Card>}

            {orderInfo && orderInfo.deliveryStatus !== 'cancelled' && orderInfo.deliveryStatus !== 'delivered' && orderInfo.riderInfo && orderInfo.riderInfo.shareUrl &&
            <iframe id="tracking-map" src={orderInfo.riderInfo.shareUrl}/>}

        </div>
    )
}

export default Track;
