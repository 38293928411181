import React from "react";
import {Link, useHistory} from "react-router-dom";
import {withRouter} from "react-router";

import Button from "../core/Button";
import Navbar from "../core/Navbar";
import NavbarGroup from "../core/Navbar/Group.jsx";
import NavbarHeading from "../core/Navbar/Heading.jsx";

import {ThemeContext} from "../core/ThemeContext";
import {SidebarStateContext} from "./context/SidebarContext";

const AppBar = props => {
    const {device, location} = props;
    const history = useHistory();

    return (
        <ThemeContext.Consumer>
            {({isDark, setDark}) => (
                <SidebarStateContext.Consumer>
                    {({leftSidebarState, rightSidebarState, setSidebarState}) => (
                        <React.Fragment>
                            <Navbar type={device} view="flat" color="warning" fixedToTop>
                                <NavbarGroup align="center">
                                    <NavbarHeading>
                                        
                                        <div className="logo-container">
                                            <div className="logo-text">    
                                                <span>Powered By</span>                                         
                                            </div>
                                            <img
                                                src={"/loadshare_networks.png"}
                                                alt="LoadShare Networks"
                                                style={{height: "32px", textAlign: "center"}}
                                            />
                                        </div>
                                    </NavbarHeading>
                                </NavbarGroup>
                                {/**<NavbarGroup align="right">
                                    <Button onClick={() => history.push('/track')}>Track Another Order</Button>
                                </NavbarGroup> */}
                            </Navbar>
                        </React.Fragment>
                    )}
                </SidebarStateContext.Consumer>
            )}
        </ThemeContext.Consumer>
    );
};

export default withRouter(AppBar);
