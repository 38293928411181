import React, {useState, useContext} from "react";

import {ThemeContext} from "./@setproduct-ui/core/ThemeContext";

import "normalize.css/normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";

import "./App.scss";

import "./@setproduct-ui/styles/setproduct.css";
import {Switch, Route, Redirect} from "react-router-dom";
import DemoAppBar from "./@setproduct-ui/demo/DemoAppBar";
import {useDevice} from "./@setproduct-ui/core/hooks/customHooks";
import Track from "./Track";


function App() {
    const [theme, setTheme] = useState(useContext(ThemeContext));
    let device = useDevice();

    return (
        <React.Fragment>
            <ThemeContext.Provider
                value={{isDark: theme.isDark, setDark: setTheme}}
            >

                <DemoAppBar device={device}/>
                <div id={"app-container"}>
                    <Switch>
                        <Route exact path={"/"}>
                            <Track/>
                        </Route>
                        <Route exact path={["/track", "/track/:trackingId"]}>
                            <Track/>
                        </Route>
                        <Route
                            exact
                            path="/t/:trackingId"
                            render={props => (
                                <Redirect to={`/track/${props.match.params.trackingId}`}/>
                            )}
                        />
                    </Switch>
                </div>

            </ThemeContext.Provider>
        </React.Fragment>
    )
}

export default App;
