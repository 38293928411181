import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
// import Spinner from "../../core/Spinner";
import Spinner from "../@setproduct-ui/core/Spinner";

const useStyles = makeStyles(() => ({
  loaderContainer: {
    height: '100%',
    width: '100%',
    opacity: '0.8'
  },
  loadingTitle: {
    fontSize: '1.5rem',
    color: 'gray',
    paddingRight: '0.3rem'
  }
}))

const SuspenseLoader = () => {
  const classes = useStyles();
  return(
    <Grid container direction="column" alignItems="center" justify="center" className={classes.loaderContainer}>
      <Grid item>
        <Grid item container direction="row">
          <Grid item>
            {/**<Typography className={classes.loadingTitle}>Loading</Typography> */}
          </Grid>
          <Grid item>
            <Spinner
                view = "smooth"
                color = "default"
                value = "undefined"
                size = "50"       
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SuspenseLoader;